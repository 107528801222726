import { Modal } from "@goalteller/app-kit/wo-library/components/atoms";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import Link from "next/link";
import { useRouter } from "next/router";

import betaConfirmationImage from "../images/beta-confirmation.svg";
import AmpImage from "./AmpImage";
import styles from "./newsletterModal.module.css";

export default function NewsletterModal({
  setShowSuccess,
  success,
}: {
  setShowSuccess: (value: boolean) => void;
  success: boolean;
}) {
  const { pathname } = useRouter();
  const isCorporate = pathname.includes("corporate");

  return (
    <Modal
      isOpen={success}
      onClose={() => {
        setShowSuccess(false);
      }}
    >
      <div className={styles.betaConfirmation}>
        <div className={styles.title}>
          {isCorporate
            ? "Thanks for your interest in GoalTeller"
            : "Welcome to GoalTeller's newsletter and Insights"}
        </div>
        {!isCorporate && (
          <p className={styles.body}>
            We are confident that we will engage and enlighten you with
            interesting and relevant data and news every week. <br />
            You can also go to our Blog page to see earlier newsletters,
            insights, articles and more
          </p>
        )}

        <div className={styles.subtitle}>
          {isCorporate && "We shall reach out to you within the next 24 hours"}
        </div>

        <AmpImage alt="" src={betaConfirmationImage} width={300} />

        {isCorporate ? (
          <Button className={styles.button} variant="primary">
            Book a tour
          </Button>
        ) : (
          <Link
            className={styles.blogButton}
            href="/blog"
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            Explore Blogs
          </Link>
        )}
      </div>
    </Modal>
  );
}
